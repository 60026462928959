import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { Page, Section, HPost, Header } from 'src/components';

const QUERY = graphql`
  query {
    allGraphCmsPost(
      filter: { displayBlog: { eq: true } }
      sort: { fields: postDate, order: DESC }
    ) {
      nodes {
        title
        subtitle
        vertical
        slug
        teaserTldr
        externalUrl
        postDate
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1)
        }
        body {
          html
        }
        author {
          id
          firstName
          lastName
          avatar {
            gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 36, aspectRatio: 1)
          }
        }
      }
    }
  }
`;

/**
 *
 */
const Blog = () => {
  const data = useStaticQuery(QUERY);
  const posts = data?.allGraphCmsPost?.nodes;

  return (
    <Page type="blog" metadata={{ title: 'The latest from Catch', path: 'blog' }}>
      <Header title="Blog" bg="var(--c-bg-0)" />
      <Section raw>
        <div>
          {posts && posts.length > 0 ? (
            posts.map((post) => (
              <HPost
                key={post.slug}
                date={post.postDate}
                link={post.externalUrl || '/post/' + post.slug}
                image={post?.image?.gatsbyImageData}
                title={post.title}
                subtitle={post.subtitle}
                authors={post.author}
                tag={post.vertical}
                teaser={post.teaserTldr}
              />
            ))
          ) : (
            <h3>No results found</h3>
          )}
        </div>
      </Section>
    </Page>
  );
};

export default Blog;
